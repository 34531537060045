<template>
  <div id="admin-panel">
    <Header active_tab="assessment"></Header>
    <div v-if="user.data.admin">
      <div class="row">
        <div class="col">
          <SmallCard
            :address="'/create-test-demo/'"
            link="gridicons:create"
            title="Create Test"
            :is-notif="false"
          />
        </div>
        <div class="col">
          <SmallCard
            :address="'/previous-test-demo/'"
            link="mdi:page-previous-outline"
            title="Previous Test"
            :is-notif="false"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col">
          <SmallCard
            title="Test Reports"
            :address="'/results/' + page_id"
            link="bx:bxs-report"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <SmallCard
            title="Tests Pending"
            :address="'/tests/' + page_id"
            link="ic:baseline-pending-actions"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import { defineAsyncComponent } from "vue";
import store from "@/store";
defineAsyncComponent(() => import("@/components/SmallCard.vue"));
const Header = defineAsyncComponent(() => import("@/components/Header"));
import db from "@/firebase";
const SmallCard = defineAsyncComponent(() => import("@/components/SmallCard"));
export default {
  mounted() {
    store.state.loading = false;
  },

  computed: {
    ...mapGetters({
      user: "user"
    })
  },

  components: {
    SmallCard,
    Header
  },
  data() {
    return { };
  }
};
</script>
<style scoped>
#admin-panel {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  overflow-x: hidden;
  /* background: var(--light-gray); */
  background-image: linear-gradient(white 5%,red);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
.notification-card {
  position: relative;
}
</style>
